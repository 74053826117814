import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from '../shared/service/message.service';
import { VsCrudService } from '../shared/service/vs-crud.service';
import { Onboarding } from './Onboarding.model';



@Injectable()
export class OnboardingService extends VsCrudService<Onboarding> {

    public url: string = "api/token";

    constructor(_http: HttpClient, _message: MessageService) {
        super(<any>_http, _message);
    }


}


