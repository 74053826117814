import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsVariablesService } from '../shared/service/global-variables.service';
import { ViewService } from './view.service';
import { View } from './View.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from '../utilitarios/util';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {
    
    constructor(
        private router: Router,        
        private fb: FormBuilder,
        ) {
    }

    ngOnInit() {
       
    }

}

