import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SetupService } from '../configuracoes/setup/setup.service';
import { UsuarioLogado } from '../seguranca/usuario/usuario-logado.model';
import { UsuarioService } from '../seguranca/usuario/usuario.service';
import { AuthService } from '../shared/auth/auth.service';
import { GlobalsVariablesService } from '../shared/service/global-variables.service';
import { MessageService } from '../shared/service/message.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.css',
        './login.responsive.css'
    ]
})
export class LoginComponent {

    public loginModel: {} = {};
    public statusLogin: boolean = true;
    private usuarioLogado: UsuarioLogado = new UsuarioLogado();
    public authToken;
    public errors;

    constructor(
        public _router: Router,
        public _messageService: MessageService,
        public _authService: AuthService,
        public _usuarioService: UsuarioService,
        public globalsVariablesService: GlobalsVariablesService,
        public cookieService: CookieService,
        public _setupService: SetupService
    ) {
        document.querySelector('body').classList.add('backLogin');
    }


    public toggleContentWrapper(margin: string){
        setTimeout(
          () => {
            let content = document.querySelector('.container-mr7') as HTMLDivElement;
            console.log('content - wrapper')
            console.log(content)
            if(content){
              content.style.marginLeft = margin;
            }
          }, 25
        )
      }


    private logar() {
        this._authService.authenticate(this.loginModel).subscribe(res => {
            this.authToken = res.mensagem;
            document.querySelector('body').classList.remove('backLogin');

            this._authService.autenticarUsuario(this.loginModel).subscribe(res => {
                this.usuarioLogado = res;

                localStorage.setItem("idUsuario", this.usuarioLogado.id.toString());

                if (this.usuarioLogado.pessoaNomeFantasia != '' && this.usuarioLogado.pessoaNomeFantasia != 'undefined') {
                    this._setupService.getSetup().then(setup => {

                        this.globalsVariablesService.setGlobalsVariables(this.usuarioLogado);
                        this.globalsVariablesService.setInformacoesSetup(setup);
                        this.globalsVariablesService.setRepresentante();
                        this.cookieService.set('user', JSON.stringify(this.loginModel), 365);
                        this.toggleContentWrapper('250px');
                        this._router.navigate(['/dashboard']);
                        localStorage.setItem("primeiroAcesso", "true");
                    }).catch(err => {
                        console.log("Falha ao carregar o SETUP: ", err);
                        this.globalsVariablesService.setGlobalsVariables(this.usuarioLogado);
                        this.cookieService.set('user', JSON.stringify(this.loginModel), 365);
                        this._router.navigate(['/dashboard']);
                    });
                }
            });

        }, error => {
            this.errors = error;
            this._messageService.error('Usuário ou senha incorretos. /n Por favor tente novamente.');
        });

    }

 

}