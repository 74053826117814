import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class RowMaskService {
    constructor(public numberPipe: DecimalPipe) {         
    }

    rowMask(row, col, mask){
        let value:any;
        switch(col.type){
            case 'number': 
                value = this.numberPipe.transform(row[col.field], mask);
                return value;
            case 'Number': 
                value = this.numberPipe.transform(row[col.field], mask);
                return value;
            case 'boolean': 
                if(row[col.field]) value = 'SIM'; else value = 'NÃO'; 
                return value;
            case 'Boolean':
                if(row[col.field]) value = 'SIM'; else value = 'NÃO';
                return value;
            case 'string': 
                return row[col.field];
            case 'String': 
                return row[col.field];
            case 'estado': 
                if(row[col.field] == "A" ){
                    return "Aberto";
                } else if(row[col.field] == "C" ) {
                    return "Cancelado";
                }else if(row[col.field] == "P" ) {
                    return "Pago";
                }else{
                    return "Indefinido";
                }                                                                             
            case 'situacaoPedido': 
                if(row[col.field] == "1N" ){
                    return "1N";
                }
                if(row[col.field] == "2N" ){
                  return "2N";                
                } else if(row[col.field] == "1S"  || row[col.field] == "3S" ) {
                    return "C";
                }else if(row[col.field] == "3N" ) {
                    return "3N";
                }else{
                    return "";
                }                                                                                
            default: 
                return row[col.field];
        }
        
    }


}