import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsVariablesService } from '../shared/service/global-variables.service';
import { OnboardingService } from './onboarding.service';
import { Onboarding } from './Onboarding.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from '../utilitarios/util';
import { environment } from '../../environments/environment';
import { MessageService } from '../shared/service/message.service';

@Component({
  selector: 'app-onboading',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {

  public onboarding: FormGroup;
  public tenant: string;
  model: Onboarding = new Onboarding();
    
  constructor(private router: Router,        
              private fb: FormBuilder,
              private _route: ActivatedRoute,
              private _service: OnboardingService,
              private _router: Router,
              private _messages: MessageService,
              ) {
      this.tenant = localStorage.getItem("tenant");    
      this.buildForm();
  }

  ngOnInit() {
      
  }

  buildForm(): void {
    this.onboarding = this.fb.group({
        'token': [''],
        'tenant': [this.tenant]
    });
  }


  public validar() {
      this.onboarding.value;
      this.model = this.onboarding.value;
      if (this.onboarding.valid) {        
        this._service.save(this.model).subscribe(res => {
          console.log("Teste console");
          console.log(res);
          if (res === 0){
            this._messages.warn("Token invalido tente novamente! ");        
          }else {
             this._messages.success("Token validado com sucesso");        
          }          
        })
      }        
      else {
          this._messages.info("Para validar o token preencha o token!");
      }
  }

}

