import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageResult, SearchParams } from '../../shared/service/generic.service';
import { MessageService } from '../../shared/service/message.service';
import { VsCrudService } from '../../shared/service/vs-crud.service';
import { Usuario } from './usuario.model';




@Injectable()
export class UsuarioService extends VsCrudService<Usuario> {

  public url: string = "api/usuarios";



  pageResult: PageResult = new PageResult();

  public modelUsuario: { login: string, password: string };

  constructor(_http: HttpClient, _message: MessageService) {
    super(<any>_http, _message);
  }

  public usuarioLogado: Usuario = new Usuario();

  public findbyLogin(login: string) {

    this.url = this.url + "/autenticar";

    this.get(this.url).subscribe(res => {
      this.usuarioLogado = <Usuario>res;
    });



    //return this.get( this.url + '/login',JSON.stringify({ login: login, password: password }) ).map(
    //    res => <Usuario>res.json());  
  }

  /*
  public findbyLogin(login: string,password:string) {        
        return this.get(`${this.url}/login${login}`).map(
             res => <Usuario>res.json()).subscribe(resultado => {
               this.usuarioLogado = resultado 
              }
              );
  }
  */



  public autenticarPdvExcluirItem(params: SearchParams): Observable<Usuario> {

    return this.post(this.url + "/autenticarPdvExcluirItem", params);
  }

  public validarSenhaUsuario(search: SearchParams) {
    return this.search(this.url + '/validarSenhaUsuario', search);
  }
}


