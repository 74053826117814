declare var window: any;
declare var require: any;

export class DynamicEnvironment {
    public get environment() {

        return window.config.environment;
    }

    public get version() {
        return require('../../package.json').version;
    }

}
