import {GenericModel} from '../shared/model/generic.model';

export class Dashboard extends GenericModel {
	
    public qtdeClientes:number;
	
	public qtdeProdutos:number;
	
	public qtdeNFSaidas:number;

	public valorVendas:number;
	
	public valorCompras:number;
	
	public valorDuplicatasReceber:number;

	public valorDuplicatasPagar:number;
}
