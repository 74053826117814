<div id="containerBreadcrumb">
      <section class="content-header">
        <div class="icon-breadcrumb">
          <i class="fi-dashboard-graphic"></i>
        </div>
        <h1>
          Onboarding Sistema          
        </h1>
        <ol class="breadcrumb">  
          <li><a href="#"> Home</a></li>
          <li class="active">Onboarding</li>
        </ol>
    
      </section>
      <body>           
            <form [formGroup]="onboarding">
                  <div class="ui-g" >
                        <div class="ui-g-12 ui-lg-3 ui-md-3 ui-sm-3" style="padding-left: 10px;" >
                              <p class="data"> Token de Ativação: </p>
                              <input class="form-control form-login" formControlName="token"  placeholder="Token" name="token" type="text" />              
                              <button type="submit" id="btn-login-access" class="btn btn-primary btn-block btn-flat" 
                              [disabled]="!onboarding.valid" (click)="validar()">Validar</button>              
                        </div>                                          
                  </div>
            </form>                      
      </body>

</div>
  