export enum KEY_CODE {
    TECLA_TAB       = 9,
    TECLA_ENTER     = 13,
    TECLA_SHIFT     = 16,
    TECLA_CTRL      = 17,
    TECLA_SETA_PARA_ESQUERDA = 37,
    TECLA_SETA_PARA_CIMA  = 38,
    TECLA_SETA_PARA_DIREITA = 39,
    TECLA_SETA_PARA_BAIXO = 40,
    TECLA_I         = 73,
    TECLA_J         = 74,
    TECLA_F01       = 112,  
    TECLA_F02       = 113,  
    TECLA_F03       = 114,  
    TECLA_F04       = 115,
    TECLA_F05       = 116,
    TECLA_F06       = 117,
    TECLA_F07       = 118,
    TECLA_F08       = 119,
    TECLA_F09       = 120,
    TECLA_F10       = 121,
    TECLA_F11       = 122,
    TECLA_F12       = 123
}