  <div id="containerBreadcrumb">
    <section class="content-header">
      <div class="icon-breadcrumb">
        <i class="fi-dashboard-graphic"></i>
      </div>
      <h1>
        Dashboard
        <small>{{version}}</small>
      </h1>
      <ol class="breadcrumb">
        <li>
          <!--Comentado versao 1 lite -->
            <form [formGroup]="filtro" class="dashboard-date-filter">
              <p class="data"> Período: </p>
              <p-calendar                
                class="calendar-filter"
                formControlName="periodo"
                selectionMode="range"
                dateFormat="dd/mm/yy"
                (onSelect)="onSelectDate()"
                [locale]="pt_BR"
                required
              >
              </p-calendar>
            </form>          
        </li>
        <li><a href="#"> Home</a></li>
        <li class="active">Dashboard</li>
      </ol>
    </section>
  </div>



      

      <div class="row dashboard-panels">
        
        <div style="width: 100%;">
          <div class="ui-sm-6 ui-md-6 ui-lg-3">
            <div class="small-box bg-aqua">
              <div class="inner">
                <h3>{{qtdeClientes | number:'1.0-0'}} </h3>
                <p>Meus Clientes</p>                
              </div>
              <div class="icon">
                <i class="fi-creative-users"></i>
              </div>
              <a routerLink="/administrativo/cliente/new" class="small-box-footer">Cadastrar <i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>
          <div class="ui-sm-6 ui-md-6 ui-lg-3">
            <div class="small-box bg-red">
              <div class="inner">
                <h3>{{qtdeProdutos | number:'1.0-0'}}</h3>
                <p>Meus Produtos</p>
              </div>
              <div class="icon">
                <i class="fi-ecommerce-package-4"></i>
              </div>
              <a routerLink="/estoque/produto-lite/new" class="small-box-footer">Cadastrar <i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>
          <div class="ui-sm-6 ui-md-6 ui-lg-3">
            <div class="small-box bg-yellow">
              <div class="inner">
                <h3>{{qtdeNFSaidas | number:'1.0-0'}}</h3>
                <p>Quantidade NFes emitidas no mês</p>
              </div>
              <div class="icon">
                <i class="fi-ecommerce-coins"></i>
              </div>
              <a routerLink="/comercial/pedido/new" class="small-box-footer">Emitir Venda<i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>

          <div class="ui-sm-6 ui-md-6 ui-lg-3">
            <div class="small-box bg-green">
              <div class="inner">
                <h3>{{valorVendas | number:'1.2-2'}} </h3>
                <p>Valor Total NFes emitidas no mês</p>
              </div>
              <div class="icon">
                <i class="fi-dashboard-pie-graphic"></i>
              </div>
              <a routerLink="/comercial/pdv/new" class="small-box-footer">Emitir Venda PDV <i class="fi-interface-right-arrow"></i></a>
            </div>
          </div>
      </div>
      <!-- <iframe title="Report Section" width="1024" height="804" src="{{urlPowerBI}}" frameborder="0" allowFullScreen="true"></iframe> 
        https://app.powerbi.com/reportEmbed?reportId=95e52b11-9e69-4686-be23-458f43f7fe0a&autoAuth=true&ctid=370dfae3-7c16-45f6-a317-c63106a02f10
      -->

      <!-- Iframe Power-BI
         <iframe title="BI-Curso" width="1024" height="804" src="https://app.powerbi.com/view?r=eyJrIjoiOWVmZjU5YjktMTE4OC00ZjAwLThkZGMtMGE2NjRhNzY4MTlhIiwidCI6IjM3MGRmYWUzLTdjMTYtNDVmNi1hMzE3LWM2MzEwNmEwMmYxMCJ9" frameborder="0" allowFullScreen="true">
         </iframe>
       -->
       
    <section class="ui-sm-12 ui-md-12 ui-lg-6 connectedSortable ui-sortable">   
      <div class="graphs-custom box box-solid">
        <p-card  [style]="{ width: '360px'}">  
          <ng-template pTemplate="header">
            <label>Vendas  e Compras </label>          
          </ng-template>
          <div class="ui-g">
            <p-chart type="pie" [data]="dadosEntradaSaida" [options]="optionsEntradaSaida"></p-chart>
          </div>
          <ng-template pTemplate="footer">          
          </ng-template>
        </p-card>
      </div> 
      <!-- <div class="graphs-custom box box-solid">
        <p-card  [style]="{ width: '360px'}">  
          <ng-template pTemplate="header">
            <label>Contas a Receber e Pagar </label>          
          </ng-template>
          <div class="ui-g">
            <p-chart type="pie" [data]="dadosReceberPagar" [options]="optionsReceberPagar"></p-chart>
          </div>
          <ng-template pTemplate="footer">          
          </ng-template>
        </p-card>
      </div> -->
      
        <!-- <div class="graphs-custom box box-solid">
          <div class="box-header ui-sortable-handle">
            <i class="fi-ecommerce-money">Financeiro</i>            
          </div>
          <div class="box-body border-radius-none">
            <p-chart type="horizontalBar" [data]="financeiro"></p-chart>
          </div>
      </div>       -->
    </section>

    <section class="ui-sm-12 ui-md-12 ui-lg-6  connectedSortable ui-sortable">

      <div class="graphs-custom box box-solid">
        <p-card  [style]="{ width: '360px'}">  
          <ng-template pTemplate="header">
            <label>Contas a Receber e Pagar </label>          
          </ng-template>
          <div class="ui-g">
            <p-chart type="pie" [data]="dadosReceberPagar" [options]="optionsReceberPagar"></p-chart>
          </div>
          <ng-template pTemplate="footer">          
          </ng-template>
        </p-card>
      </div> 
      
          <!-- <div class="graphs-custom box box-solid bg-teal-gradient">
            <div class="box-header ui-sortable-handle">
              <i class="fi-interface-password">Vendas Anual</i>              
            </div>
            <div class="box-body border-radius-none">
              <p-chart type="line" [data]="compras"></p-chart>
            </div>
          </div> -->
      
      
      <div class="graphs-custom box box-warning">
        <div class="box-header ui-sortable-handle" >
          <i class="fi-ecommerce-like-1"></i>
          <h3 class="box-title">Últimas Notificações</h3>
        </div>
          <div class="box-body">
            <ul class="todo-list ui-sortable">
              <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text"> Dias restantes para expirar o certificado : </span>
                <small class="label label-danger" style="font-size: 100%;"><i class="fa fa-clock-o"></i>  {{mensagemCertificado}}</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
              <!-- <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Preparar produto para entrega!</span>
                <small class="label label-info" style="font-size: 100%;"><i class="fa fa-clock-o"></i> 4 horas</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
              <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Realizar pedido ao Fornecedor de peças</span>
                <small class="label label-success"><i class="fa fa-clock-o"></i> 3 dia(s)</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li>
              <li>
                <span class="handle ui-sortable-handle">
                  <i class="fa fa-ellipsis-v"></i>
                  <i class="fa fa-ellipsis-v"></i>
                </span>
                <span class="text">Atualizar Estoque</span>
                <small class="label label-default"><i class="fa fa-clock-o"></i> 10 dia(s)</small>
                <div class="tools">
                  <i class="fa fa-edit"></i>
                  <i class="fa fa-trash-o"></i>
                </div>
              </li> -->
            </ul>
          </div>
        <!-- <div class="box-footer clearfix no-border">
          <button type="button" class="btn btn-default bg-aqua pull-right"><i class="fa fa-plus"></i> Adicionar Notificação</button>
        </div> -->
      </div>

    


    </section>
  </div>
