<div id="login-block" class="ui-g-12 ui-md-12" *ngIf="!_authService.isLoggedIn" style="background-color: #ffffff;">
    <div class="row">
        <div class="col-xs-12 col-md-6 col-lg-5" s>
            <div class="row">
                <div class="vertical-offset col-xs-offset-2 col-xs-8">
                    <img src="../assets/img/logo/pequeno/logomarca.png">
                    <br>
                    <h4 class="vertical-offset">Para entrar no <strong>MR7ERP</strong> informe seus dados abaixo</h4>
                    <form (ngSubmit)="logar()" novalidate="" class="vertical-offset" id="login-form" method="post">                        
                        <div class="form-group has-feedback form-login">
                            <label for="email">E-mail</label>
                            <input class="form-control form-login" placeholder="e-mail" name="login" type="text" autofocus [(ngModel)]="loginModel.login"/>
                        </div>
                        <div class="form-group has-feedback form-login">
                            <label for="password">Senha</label>
                            <input class="form-control form-login" placeholder="senha" name="password" type="password" [(ngModel)]="loginModel.password"/>
                        </div>
                        <br>
                        <button type="submit" class="btn btn-primary btn-block btn-flat">Entrar</button>
                    </form>
                </div>
            </div>

        </div>
        <div class="ui-g-6 ui-md-6">
            <div id="login-box-text" style="color: #0966A0 !important;">
                <h1 style="color: #0966A0 !important;">GESTÃO
                <span style="color: #0966A0 !important;">SIMPLIFICADA</span></h1>
                <h2 style="color: #0966A0 !important;" >EMISSÃO DE NOTAS DE FORMA PRÁTICA!</h2>
            </div>
        </div>





    </div>
 
</div>